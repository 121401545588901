<template>
	<div class="shop-container">
		<div v-if="agentInfo.setting.theme == 0 || !agentInfo.setting.theme" class="tmp1">
			<div class="header">
				<div class="avatar">
					<van-image
						round
						fit="contain"
						width="1.1rem"
						height="1.1rem"
						:src="!!agentInfo.avatar? 'http://' + agentInfo.avatar:'https://doc.yctop.com/weapp/zjhy/static/2021-1-26/avatar-h5.png'"
					/>
				</div>
				<div class="title">
					<p>{{agentInfo.name}}
						<template v-if="!!agentInfo.lastLoginTimeDesc">
							<van-tag 
								round 
								type="primary" 
								class="last_login_time" 
								text-color="#FFE9D4"
								color="#FF7D5A">{{agentInfo.lastLoginTimeDesc}}
							</van-tag>
						</template>
					</p>
					<p class="company">
						<span class="fans-name">粉丝数</span>
						<span>{{agentInfo.fanCnt}}</span>
					</p>
				</div>
				<div class="favor">
					<van-button class="favor-btn favor-tmp1" v-if="!agentInfo.favored" @click="isFavored(agentInfo.id)">
						<i class="iconjia iconfont"></i>
						关注
					</van-button>
					<van-button class="favor-btn favor-tmp1" v-else @click="cancelFavored(agentInfo.id)">
						<i class="iconduihao iconfont"></i>
						已关注
					</van-button>
				</div>
			</div>
			<div class="addr address-call">
				<div class='addr-left'>
					<div v-if="!!agentInfo.company_title">
						<van-icon class-prefix="iconqiye iconfont" style="margin-right: 0.2rem;" />
						{{agentInfo.company_title}}
					</div>
					<div class='addr-cell' v-if="!!agentInfo.address">
						<van-icon class-prefix="icondizhi-2 iconfont" style="margin-right: 0.2rem;" />
						{{agentInfo.address}}
					</div>
				</div>
				<div class="address-call-right">
					<div class="placeholder-line"  v-if="!!agentInfo.company_title || !!agentInfo.address"></div>
					<a :href="agentContact ? ('tel:' + agentContact) : '#'"  v-if="isCustom">
						<i class="icondianhua1 iconfont"></i>
					</a>
					<i class="icondianhua1 iconfont" v-else  @click="phoneCall"></i>
				</div>
			</div>
		</div>
		<div v-if="agentInfo.setting.theme == 1" class="tmp2">
			<div class="header"></div>
			<div class="body">
				<div class="avatar">
					<van-image
						round
						fit="contain"
						width="1.1rem"
						height="1.1rem"
						:src="!!agentInfo.avatar?'http://' + agentInfo.avatar:'https://doc.yctop.com/weapp/zjhy/static/2021-1-26/avatar-h5.png'"
					/>
				</div>
				<div class="fans">
					<span class="fans-name">粉丝数</span>
					<span>{{agentInfo.fanCnt}}</span>
				</div>
				<div class="title">
					<p class="name">{{agentInfo.name}}
						<template v-if="!!agentInfo.lastLoginTimeDesc">
							<van-tag 
								round 
								type="primary" 
								class="last_login_time" 
								text-color="#FF5226"
								color="#FFEDE9">{{agentInfo.lastLoginTimeDesc}}
							</van-tag>
						</template>
					</p>
					<p class="company" v-if="!!agentInfo.company_title">
						<van-icon class-prefix="iconqiye iconfont" style="margin-right: 0.08rem;" />{{agentInfo.company_title}}
					</p>
					<p class="address-call">
						<span class='addr-left'>
							<van-icon class-prefix="icondizhi-2 iconfont" style="margin-right: 0.15rem;" v-if="!!agentInfo.address" />
							{{agentInfo.address}}
						</span>
						<span class="address-call-right">
							<i class="placeholder-line" v-if="!!agentInfo.company_title || !!agentInfo.address"></i>
							<a :href="agentContact ? ('tel:' + agentContact) : '#'" v-if="isCustom">
								<i class="icondianhua1 iconfont"></i>
							</a>
							<i class="icondianhua1 iconfont" v-else  @click="phoneCall"></i>
						</span>
					</p>
				</div>
				<div class="favor">
					<van-button class="favor-btn favor-tmp2" v-if="!agentInfo.favored" @click="isFavored(agentInfo.id)">
						<i class="iconjia iconfont"></i>
						关注
					</van-button>
					<van-button class="favor-btn favor-tmp2" v-else @click="cancelFavored(agentInfo.id)">
						<i class="iconduihao iconfont"></i>
						已关注
					</van-button>
				</div>
			</div>
		</div>
		<div class="product-container clearfix">
			<div class="desc">
				热门活动
			</div>
			<div class="items">
				<div v-for="(item, idx) in productList" :key="idx">
					<div :class="idx % 2 == 0 ? 'item left' : 'item'" @click="gotoProductDetailPage(item)">
						<div class="title">
							{{item.title}}
						</div>
						<div class="from-to">
							<span class="from">
								{{item.dep}}
							</span>
							<span class="iconfont iconfeiji800"></span>
							<span class="to">
								{{item.des}}
							</span>
						</div>
						<div class="intros">
							<div class="price">
								<template v-if="item.price_type == 0">
									<em class="ask">{{item.price_remark}}</em>
								</template>
								<template v-if="item.price_type == 1">
									<i>￥</i><em>{{item.min_price}}</em>起
								</template>
								
							</div>
							<div class="density" v-if="!!item.density">
								{{item.density}}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- <van-button icon="plus" type="primary" @click="createPreOrder">询价</van-button> -->

	</div>
</template>
<script>
	/* eslint-disable */
	import { mapState, mapGetters } from 'vuex';
	import { Dialog } from 'vant';
	import  REQ_HANDLE from '@/utils/request.js'
	import UTILS from '@/utils/common-utils';
	export default {
		data: ()=> ({
			agentId: null,
			agentInfo: {
				avatar: '',
				intros: '',
				last_login_at: '',
				name: '',
				setting: {
					theme: 0,
				},
				telephone: null,
				fanCnt:0,
			},
			productList: [],
			agentContact:'',
		}),
		computed: {
			...mapState({
				usr: state => state.usr,
				token: state => state.token,
				wx_usr: state => state.wx_usr,
				mall_usr: state => state.mall_usr
			}),
			...mapGetters({
				login: 'checkLogin',
				isCustom: 'isCustom',
			})
		},
		created: function() {
			this.agentId = this.$route.params.shopId
		},
		mounted: async function() {
			await this.loadAgentInfo() //关注状态，isCustom的话 就取用，否则不显示
			await this.loadProductList()
			this.agentInfo.name && this.$store.commit('updatePageTitle',{title: this.agentInfo.name})
			this.agentContact= await UTILS.getAgentContact(this.agentId)
		},
		methods: {
			openKF5() {
				window.KF5SupportBoxAPI.open();
			},
			loadAgentInfo: async function() {
				this.agentInfo= await this.$store.dispatch('getAgentInfo',{shopId:this.agentId})
				this.agentInfo.lastLoginTimeDesc = this._getLastLoginTimeDesc()
			},
			loadProductList: async function() {
				let url = '/api-mall/products/search/' + this.agentId,
					res = await REQ_HANDLE.agent.$get(url)
				if(!!res) {
					this.productList=res.data.result
				}
			},
			_getMinPriceFromProduct(p) {
				let min = 9999.00
				if(p.price_type == 1) {
					let {q1 = 0, q2 = 0, q3 = 0, q4 = 0, q5 = 0} = p
					q1 = parseFloat(q1)
					q2 = parseFloat(q2)
					q3 = parseFloat(q3)
					q4 = parseFloat(q4)
					q5 = parseFloat(q5)
					if (!!q5 && q5 < min) {
						min = q5
					}
					if (!!q4 && q4 < min) {
						min = q4
					}
					if (!!q3 && q3 < min) {
						min = q3
					}
					if (!!q2 && q2 < min) {
						min = q2
					}
					if (!!q1 && q1 < min) {
						min = q1
					}
					return min
				}
			},
			_getLastLoginTimeDesc() {
				let time = this.agentInfo.last_login_at || ''
				if (!time) {
					return;
				}
				let diff = new Date().getTime() - new Date(time).getTime()
				console.log(diff / 1000)
				if (!diff) {
					return
				} 
				if (diff > 1000 * 3600 * 24 * 7) {
					return //7天以上不显示
				}
				let day = parseInt(diff / (1000 * 3600 * 24)),
					hour = parseInt(diff % (1000 * 3600 * 24) / (1000 * 3600)),
					minute = parseInt(diff % (1000 * 3600) / (1000 * 60))
				if (day > 0) {
					return day + '天前在线'
				}
				if (hour > 0) {
					return hour + '小时前在线'
				}
				if (minute > 0) {
					return minute + '分钟前在线'
				}
				return '当前在线'
			},
			gotoProductDetailPage: function(product) {
				let pId = !!product ? product.id : null
				pId && this.$router.push('/product/' + this.agentId + '/' + pId)
			},
			phoneCall: function(){
				Dialog.confirm({
					title: '提示',
					message: '为了方便卖家能与您及时沟通，请验证手机号!',
				})
				.then(() => {
					this.$router.push('/bind-mobile')
				})
				.catch(() => {
					// on cancel
				});
			},
			isFavored: async function(id){
				if(this.isCustom) {
					let url = '/api-mall/favor',
						params={
							agent_id: id
						},
						res = await REQ_HANDLE.agent.$post(url, params)
					!!res && await this.loadAgentInfo()
				} else {
					Dialog.confirm({
						title: '提示',
						message: '为了方便卖家能与您及时沟通，请验证手机号!',
					})
					.then(() => {
						this.$router.push('/bind-mobile')
					})
					.catch(() => {
						// on cancel
					});
				}
			},
			cancelFavored: function(id){
				Dialog.confirm({
					message: '确认取消关注？',
					confirmButtonText:'取消关注',
					cancelButtonText:'再想想',
				})
				.then( async () => {
					let url = '/api-mall/favor/' + id,
						res = await REQ_HANDLE.agent.$delete(url)
					!!res && await this.loadAgentInfo()
				})
				.catch(() => {
					// on cancel
				});
			},
		}
	};
</script>
<style scoped >
@import "~@/assets/css/shop.css";
</style>